export function getCookie(name: string) {
    const reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)')
    const arr = document.cookie.match(reg)
    if (arr) {
        return (arr[2])
    } else {
        return null
    }
}

export function setCookie(cName: string, value: any, expiredays: number) {
    const exdate = new Date()
    exdate.setDate(exdate.getDate() + expiredays)
    document.cookie = cName + '=' + escape(value) + ((!expiredays) ? '' :
        ';expires=' + exdate.toLocaleDateString())
}

export function delCookie(name: string) {
    const exp = new Date()
    exp.setTime(exp.getTime() - 1)
    const cval = getCookie(name)
    if (cval != null) {
        document.cookie = name + '=' + cval + ';expires=' + exp.toLocaleDateString()
    }
}
