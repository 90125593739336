


























































import {Component, Vue} from 'vue-property-decorator'
import {createRequest} from '~/utils/network-request'
import {ReportPermission} from '~/utils/permissions'
import {toCsv} from '~/utils/misc'
import {getCookie, setCookie} from '~/utils/cookie'

class Row {
    _id: number
    title: string
    data: number[]

    constructor(obj) {
        this._id = obj._id
        this.title = obj.title
        this.data = obj.data
    }

    getValue(i, showDecreased = false) {
        const v = this.data[i]
        if (v === undefined)
            return '-'
        if (!showDecreased || this.data[i + 1] === undefined || v === 0)
            return v.toString()
        return `${v} (${Row.decreasedPercent(this.data[i + 1], this.data[i])})`
    }

    static decreasedPercent(num: number, base: number) {
        const x = 1 - (num / base)
        return `-${Math.round(x * 100)}%`
    }
}

@Component({
    components: {},
    metaInfo() {
        return {
            title: 'Course Series Report'
        }
    }
})

export default class CourseSeriesReport extends Vue {
    static permission = ReportPermission.SeriesReport

    series: { title: string, _id: number }[] = []
    selected: number[] = []
    tableData: Row[] = []
    length = 0
    indexArray: number[] = []

    start: Date | null = null
    end: Date | null = null

    showDecreased = false

    sidCookieName = 'sReportSids'
    showDCookieName = 'sReportShowDec'
    async created() {
        await this.updateSeries()
        const c = getCookie(this.sidCookieName)
        if (c) {
            this.selected = c.split('.').map((s) => {
                return Number(s)
            })
        } else {
            this.selected = this.series.map((s) => {
                return s._id
            })
        }
        const cd = getCookie(this.showDCookieName)
        if (cd === 't')
            this.showDecreased = true
        await this.getReport()
    }
    async updateSeries() {
        const res = await createRequest('/courses/series', 'get', {limit: 0}).send()
        this.series = res.data.series
    }
    async getReport() {
        const q = {series_ids: this.selected.join(',')}
        if(this.start)
            q['start'] = this.start.getTime()
        if(this.end) {
            const d = new Date(this.end)
            d.setDate(d.getDate() + 1)
            q['end'] = d.getTime()
        }
        const res = await createRequest('/courses/series/report', 'get', q).send()
        const report: { _id: number, title: string, data: number[] }[] = res.data.report
        this.length = Math.max(...report.map((r) => {
            return r.data.length
        }))
        this.indexArray = Array.from(Array(this.length).keys())
        const total: number[] = this.indexArray.map((i) => {
            return 0
        })
        this.tableData = report.map((r) => {
            for (const i of this.indexArray) {
                if (r.data[i])
                    total[i] += r.data[i]
            }
            return new Row(r)
        })
        this.tableData.push(new Row({
            _id: -1,
            title: 'total',
            data: total
        }))

        setCookie(this.sidCookieName, this.selected.join('.'), 100)
        setCookie(this.showDCookieName, this.showDecreased ? 't' : 'f', 100)
    }

    downloadCsv() {
        const table: string[][] = []
        const header = ['Title']
        for (const i of this.indexArray) {
            header.push(`${i + 1}期`)
        }
        table.push(header)
        for (const r of this.tableData) {
            const row = [r.title]
            for (const i of this.indexArray) {
                row.push(r.getValue(i))
            }
            table.push(row)
        }

        const filename = 'series_report.csv'
        const element = document.createElement('a')
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(toCsv(table)))
        element.setAttribute('download', filename)

        element.style.display = 'none'
        document.body.appendChild(element)

        element.click()

        document.body.removeChild(element)
    }
}

